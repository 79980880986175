// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2022-01-20T14:18:47.411Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            endpoint: 'https://7e5t6xzb89.execute-api.eu-central-1.amazonaws.com/stage',
            name: 'ApiGatewayRestApi',
            region: 'eu-central-1'
        }
    ],
    aws_cognito_identity_pool_id: 'eu-central-1:fad4ccad-99e9-40db-a1db-f076f8f5ddff',
    aws_cognito_region: 'eu-central-1',
    aws_project_region: 'eu-central-1',
    aws_user_files_s3_bucket: 'stage-fraunhofer-server-attachments',
    aws_user_files_s3_bucket_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_KJWaeN0xK',
    aws_user_pools_web_client_id: '51dh9grof3dqme5a69ofli2l4u'
};

export default awsmobile;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, InputAdornment } from '@material-ui/core';
import * as PropTypes from 'prop-types';

import FormInput from '../../../components/FormInput/FormInput';

const AddHardwareDialog = props => {
    const [ type, setType ] = useState('');
    const [ chargeTrottling, setChargeTrottling ] = useState('');
    const [ connectionMethod, setConnectionMethod ] = useState('');

    const reset = () => {
        setChargeTrottling('');
        setConnectionMethod('');
    }

    useEffect(() => {
        reset();
        return () => reset();
    }, [type]); // Only re-call effect if value changes

    const submitHandler = () => {
        props.onSubmit({
            type,
            chargeTrottling: type === 'DC' ? `${chargeTrottling} kW` : `${chargeTrottling}`,
            connectionMethod
        });

        setType('');
        reset();
    };

    const formIsValid = () => {

        let chargeThrottlingIsValid = chargeTrottling !== ''

        if(type === 'DC') {
            chargeThrottlingIsValid = chargeTrottling >= 50 && chargeTrottling <= 150;
        }

        return type && chargeThrottlingIsValid && connectionMethod;
    }

    const chargeThrottlingInput = isDC => {

        const options = isDC ?
            { min: 50, max: 150, step: 1}
            : [
                { label: '11 kW', value: '11 kW' },
                { label: '22 kW', value: '22 kW' },
            ];
        return isDC ? (
            <FormInput
                elementType="number"
                label="Ladeleistung"
                value={chargeTrottling}
                changed={e => setChargeTrottling(e.target.value)}
                required
                helpText="Ladeleistung im Bereich von 50 - 150 kW frei wählbar"
                inputProps={{ ...options }} // Note: inputProps / InputProps must coexists @see https://material-ui.com/api/text-field/
                InputProps={{
                    endAdornment: <InputAdornment position="end">kW</InputAdornment> // Note: inputProps / InputProps must coexists @see https://material-ui.com/api/text-field/
                }}
            />
        ) : <FormInput
                elementType="select"
                label="Ladeleistung"
                value={chargeTrottling}
                changed={e => setChargeTrottling(e.target.value)}
                required
                options={[
                    { label: 'Bitte wählen', value: '' },
                    ...options,
                ]}
    />
    };

  const gsmInput = (isDc) => {
    const options = [
      { label: 'Bitte wählen', value: '' },
    ];

    if (!isDc) {
      options.push({ label: 'LAN (2 Kabel)', value: 'LAN (2 Kabel)' });
    } else {
      options.push({ label: 'LAN (1 Kabel)', value: 'LAN (1 Kabel)' });
    }

    options.push({ label: 'GSM', value: 'GSM' });

      return (
        <FormInput
          elementType="select"
          label="Art der Verbindung"
          value={connectionMethod}
          changed={e => setConnectionMethod(e.target.value)}
          required={true}
          options={options}
        />
      );
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle>Ladeinfrastruktur hinzufügen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bitte geben Sie an, welche Ladeinfrastruktur Sie abrufen möchten.
                </DialogContentText>

                <FormInput
                    elementType="select"
                    label="Ladestationstyp"
                    value={type}
                    changed={e => setType(e.target.value)}
                    required={true}
                    options={[
                        { label: 'Bitte wählen', value: '' },
                        { label: 'AC', value: 'AC' },
                        { label: 'DC', value: 'DC' },
                    ]}
                />

                {type && (
                    <>
                        {chargeThrottlingInput(type === 'DC')}
                        {gsmInput(type === 'DC')}
                        {connectionMethod === 'GSM' && <Typography color="warning">Bitte stellen Sie sicher, dass die Verbindungsqualität ausreichend ist.</Typography>}
                    </>
                )}
              <sub style={{float: 'left', marginTop: '8px'}}>* Pflichtfeld</sub>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={props.onClose} color="default">Schließen</Button>
                <Button type="button" onClick={submitHandler} color="default" disabled={!formIsValid()}>Speichern</Button>
            </DialogActions>
        </Dialog>
    );
};

AddHardwareDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default AddHardwareDialog;

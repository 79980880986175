import * as actionTypes from './actionTypes';

export const ordersListStart = () => ({
    type: actionTypes.ORDERS_LIST_START
});

export const ordersListSuccess = orderList => ({
    type: actionTypes.ORDERS_LIST_SUCCESS,
    orderList
});

export const ordersListFail = error => ({
    type: actionTypes.ORDERS_LIST_FAIL,
    error
});

export const newOrderStart = formData => ({
    type: actionTypes.NEW_ORDER_START,
    formData
});

export const newOrderSuccess = () => ({
    type: actionTypes.NEW_ORDER_SUCCESS,
});

export const newOrderFail = error => ({
    type: actionTypes.NEW_ORDER_FAIL,
    error
});

export const orderGetStart = () => ({
    type: actionTypes.ORDER_GET_START
});

export const orderGetSuccess = order => ({
    type: actionTypes.ORDER_GET_SUCCESS,
    order
});

export const orderGetFail = error => ({
    type: actionTypes.ORDER_GET_FAIL,
    error
});

export const orderGetAttachmentStart = () => ({
    type: actionTypes.ORDER_GET_ATTACHMENT_START,
});

export const orderGetAttachmentSuccess = () => ({
    type: actionTypes.ORDER_GET_ATTACHMENT_SUCCESS
});

export const orderGetAttachmentFail = error => ({
    type: actionTypes.ORDER_GET_ATTACHMENT_FAIL,
    error
});

export const closeOrderForm = () => ({
    type: actionTypes.CLOSE_ORDER_FORM
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as orderOperations from '../../store/orders/operations';
import * as classes from './OrderDetail.module.css';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import AttachmentList from '../../components/AttachmentList/AttachmentList';

class OrderDetail extends Component {

    componentDidMount() {
        this.props.onGetOrder(this.props.match.params.userId, this.props.match.params.orderId);
    }

    downloadAttachmentHandler = attachment => {
        console.log(`Downloading Attachment ${attachment.attachmentId}`);

        this.props.getOrderAttachment(this.props.match.params.userId, this.props.match.params.orderId, attachment.attachmentId);
    };

    render() {
        return (
            <Paper className={classes.OrderDetail}>
                <Typography variant="h5" component="h1" gutterBottom>Zusammenfassung</Typography>
                {this.props.error && <Typography variant="body2" color="error">{this.props.error}</Typography>}

                {this.props.order && (
                    <>
                        <Typography variant="body2">Der folgende Abrufschein wurde am {this.props.order.createdAt.toLocaleDateString()} übermittelt.</Typography>
                        <OrderSummary formData={this.props.order.formData} />
                        <AttachmentList attachments={this.props.order.attachments} onDownload={this.downloadAttachmentHandler} />

                        <div className={classes.ButtonGroup}>
                            <Button component={Link} to="/" color="primary" variant="contained">Schließen</Button>
                        </div>
                    </>
                )}
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    order: state.orders.order,
    loading: state.orders.loading,
    error: state.orders.error
});

const mapDispatchToProps = dispatch => ({
    onGetOrder: (userId, orderId) => dispatch(orderOperations.getOrder(userId, orderId)),
    getOrderAttachment: (userId, orderId, attachmentID) => dispatch(orderOperations.getOrderAttachment(userId, orderId, attachmentID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);

import React from 'react';
import { Container, CssBaseline, ThemeProvider } from '@material-ui/core';

import * as classes from './Layout.module.css';
import Navigation from '../../components/Navigation/Navigation';
import theme from '../../config/theme';

const Layout = props => (
    <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className={classes.Layout}>
            <Navigation />

            <Container component="main" maxWidth="md">
                {props.children}
            </Container>
        </div>
    </ThemeProvider>
);

export default Layout;

import React from 'react';
import * as PropTypes from 'prop-types';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import * as classes from './FormInput.module.css';
import HtmlHelpText from '../HtmlHelpText/HtmlHelpText';

const DEFAULT_VARIANT = 'standard';

const ELEMENT_TYPE_TEXTAREA = 'textarea';
const ELEMENT_TYPE_TEXT = 'text';
const ELEMENT_TYPE_NUMBER = 'number';
const ELEMENT_TYPE_SELECT = 'select';
const ELEMENT_TYPE_FILE = 'file';

const ELEMENT_TYPES = [ELEMENT_TYPE_TEXTAREA, ELEMENT_TYPE_TEXT, ELEMENT_TYPE_NUMBER, ELEMENT_TYPE_SELECT, ELEMENT_TYPE_FILE];

const FormInput = ({ elementType, id, label, value, changed, helpText, placeholder, required, options, variant, autoFocus, inputProps, InputProps }) => {
    let inputElement;

    const handleFocus = input => input && autoFocus && input.focus();

    switch (elementType) {
        case ELEMENT_TYPE_TEXTAREA:
            inputElement = <TextField
                multiline
                label={label}
                value={value}
                onChange={changed}
                fullWidth
                helperText={helpText}
                rows={4}
                placeholder={placeholder}
                required={required}
                variant={variant}
                inputRef={input => handleFocus(input)}
                inputProps={inputProps} // NOTE: inputProps / InputProps must coexists @see https://material-ui.com/api/text-field/
                InputProps={InputProps} // NOTE: inputProps / InputProps must coexists @see https://material-ui.com/api/text-field/
                FormHelperTextProps={{
                    component: () => <HtmlHelpText text={helpText} />
                }}
            />;
            break;
        case ELEMENT_TYPE_TEXT:
            inputElement = <TextField
                label={label}
                value={value}
                onChange={changed}
                fullWidth
                helperText={helpText}
                placeholder={placeholder}
                required={required}
                variant={variant}
                inputRef={input => handleFocus(input)}
                inputProps={inputProps}
                InputProps={InputProps}
            />;
            break;
        case ELEMENT_TYPE_NUMBER:
            inputElement = <TextField
                type={ELEMENT_TYPE_NUMBER}
                label={label}
                value={value}
                onChange={changed}
                fullWidth
                helperText={helpText}
                placeholder={placeholder}
                required={required}
                variant={variant}
                inputRef={input => handleFocus(input)}
                inputProps={inputProps}
                InputProps={InputProps}
            />;
            break;
        case ELEMENT_TYPE_SELECT:
            inputElement = <FormControl fullWidth required={required}>
                <InputLabel id={id}>{label}</InputLabel>
                <Select
                    labelId={id}
                    value={value}
                    onChange={changed}
                    autoWidth={false}
                    variant={variant}
                >
                    {options.map(o => (
                        <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                    ))}
                </Select>
                {helpText && helpText.length > 0 && <FormHelperText component={() => <HtmlHelpText text={helpText} />} />}
            </FormControl>;
            break;
        case ELEMENT_TYPE_FILE:
            inputElement = <div className={classes.FileControl}>
                <label htmlFor={id}>{label}{required ? '*' : ''}</label>
                <input id={id} type={ELEMENT_TYPE_FILE} onChange={changed} required={required} />
            </div>;
            break;
        default:
            throw new Error('elementType unkown');
    }

    return (
        <div className={classes.FormGroup}>
            { inputElement }
        </div>
    );
};

FormInput.defaultProps = {
    id: null,
    touched: false,
    invalid: false,
    helpText: "",
    options: [],
    required: false,
    placeholder: "",
    defaultVariant: DEFAULT_VARIANT
};

FormInput.propTypes = {
    id: PropTypes.string,
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    elementType: PropTypes.oneOf(ELEMENT_TYPES),
    label: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    value: PropTypes.any.isRequired, // @todo oneOf(WHAT), we should avoid using any
    changed: PropTypes.func.isRequired,
    options: PropTypes.array,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultVariant: PropTypes.oneOf([DEFAULT_VARIANT]),
};

export default FormInput;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as classes from './Home.module.css';
import Greeting from './Greeting/Greeting';
import FormOverview from './FormOverview/FormOverview';

class Home extends Component {

    state = {
        newOrderDialogVisible: true
    };

    render() {
        return (
            <div className={classes.Home}>
                <Greeting/>

                <FormOverview/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(Home);

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

export const AUTH_CHALLENGE_NEW_PASSWORD_START = 'AUTH_CHALLENGE_NEW_PASSWORD_START';
export const AUTH_CHALLENGE_NEW_PASSWORD_FAIL = 'AUTH_CHALLENGE_NEW_PASSWORD_FAIL';

export const AUTH_PASSWORD_RESET_START = 'AUTH_PASSWORD_RESET_START';
export const AUTH_PASSWORD_RESET_SUCCESS = 'AUTH_PASSWORD_RESET_SUCCESS';
export const AUTH_PASSWORD_RESET_FAIL = 'AUTH_PASSWORD_RESET_FAIL';

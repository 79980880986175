import { Auth, API } from 'aws-amplify';

import * as actions from './actions';

export const signin = (username, password) => async dispatch => {
    try {
        dispatch(actions.authStart());

        const user = await Auth.signIn(username, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return dispatch(actions.authChallengeNewPasswordStart(user));
        }

        const account = await API.get('default', '/account', null);
        dispatch(actions.authSuccess(user.signInUserSession.idToken.payload, account));
    } catch (err) {
        dispatch(actions.authFail(err));
    }
};

export const restoreSession = () => async dispatch => {
    try {
        dispatch(actions.authStart());

        const session = await Auth.currentSession();
        const account = await API.get('default', '/account', null);

        dispatch(actions.authSuccess(session.idToken.payload, account));
    } catch (err) {
        dispatch(actions.authLogoutSuccess());
    }
};

export const completeNewPasswordChallenge = (challenge, password, username, challengeCode) => async dispatch => {
    try {
        if (challenge.type === 'PASSWORD_RESET') {
            await Auth.forgotPasswordSubmit(username, challengeCode, password, null);
            dispatch(signin(username, password));
        } else {
            const user = await Auth.completeNewPassword(challenge.user, password, null);
            const account = await API.get('default', '/account', null);
            dispatch(actions.authSuccess(user.signInUserSession.idToken.payload, account));
        }
    } catch (err) {
        dispatch(actions.authChallengeNewPasswordFail(err));
    }
};

export const resetPassword = username => async dispatch => {
    try {
        dispatch(actions.authPasswordResetStart());

        const res = await Auth.forgotPassword(username);
        dispatch(actions.authPasswordResetSuccess(res));
    } catch (err) {
        dispatch(actions.authPasswordResetFail());
    }
};

export const logout = () => async dispatch => {
    try {
        dispatch(actions.authLogoutStart());

        await Auth.signOut();
        dispatch(actions.authLogoutSuccess());
    } catch (err) {
        dispatch(actions.authLogoutFail(err));
    }
};

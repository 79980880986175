import React, { useEffect, useState } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import { Add as AddIcon} from '@material-ui/icons';
import { connect } from 'react-redux';

import * as classes from './FormOverview.module.css';
import * as orderOperations from '../../../store/orders/operations';
import OrderList from '../../../components/OrderList/OrderList';
import SelectOrderTypeDialog from '../../../components/SelectOrderTypeDialog/SelectOrderTypeDialog';

const FormOverview = props => {

    const [ modalVisible, setModalVisible ] = useState(false);

    const getOrderList = props.getOrderList;
    useEffect(() => {
        getOrderList();
    }, [ getOrderList ]);

    return (
        <Paper className={classes.FormOverview}>
            <div className={classes.ButtonBar}>
                <Button variant="contained" color="primary" size="small" onClick={() => setModalVisible(true)}>
                    <AddIcon/>
                </Button>
            </div>

            <Typography variant="h5" component="h2">Übersicht der Abrufscheine</Typography>
            {props.error && <Typography variant="body2" color="error">{props.error}</Typography> }
            <SelectOrderTypeDialog open={modalVisible} onClose={() => setModalVisible(false)}/>

            <OrderList orders={props.orderList}/>
        </Paper>
    );
};

const mapStateToProps = state => ({
    orderList: state.orders.orderList,
    error: state.orders.error,
});

const mapDispatchToProps = dispatch => ({
    getOrderList: () => dispatch(orderOperations.getOrderList())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormOverview);

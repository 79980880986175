import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LinearPageProgress = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0
  }
})(LinearProgress);

export default LinearPageProgress;
import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';

import * as classes from './OrderSummary.module.css';

const OrderSummary = props => (
    <div className={classes.OrderSummary}>
        {props.formData && props.formData.groups && props.formData.groups.map(group => (
            <div key={group.label}>
                <h3 className={classes.OrderGroupName}>{group.label}</h3>
                {group.values && group.values.map(value => (
                    <div key={value.label}>
                        <span className={classes.FormLabel}>{value.label}</span>
                        <span className={classes.FormValue}>{value.value}</span>
                    </div>
                ))}

                {group.positions && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nr.</TableCell>
                                <TableCell>Positionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {group.positions.map((position, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{ position.join(', ')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        ))}
    </div>
);

OrderSummary.propTypes = {
    formData: PropTypes.object.isRequired,
    hideHeadline: PropTypes.bool
};

export default OrderSummary;

import * as actionTypes from './actionTypes';
import { getErrorMessage } from '../../utils/error-utils';

const initialState = {
    account: null,
    user: null,
    error: null,
    loading: false,
    challenge: null,
};


const authStart = state => ({
    ...state,
    error: null,
    user: null,
    loading: true
});

const authSuccess = (state, action) => ({
    ...state,
    error: null,
    user: action.user,
    account: action.account,
    loading: false,
    challenge: null,
});

const authFail = (state, action) => ({
    ...state,
    error: getErrorMessage(action.error),
    user: null,
    loading: false
});

const authLogoutStart = state => ({
    ...state,
    error: null,
    loading: true
});

const authLogoutSuccess = state => ({
    ...state,
    error: null,
    user: null,
    loading: false
});

const authLogoutFail = (state, action) => ({
    ...state,
    error: getErrorMessage(action.error),
    loading: false
});

const authChallengeNewPasswordStart = (state, action) => ({
    ...state,
    challenge: {
        type: 'NEW_PASSWORD',
        user: action.user
    },
    loading: false
});

const authChallengeNewPasswordFail = (state, action) => ({
    ...state,
    error: getErrorMessage(action.error),
    loading: false
});

const authPasswordResetStart = state => ({
    ...state,
    error: null,
    loading: true
});

const authPasswordResetSuccess = (state, action) => ({
    ...state,
    error: null,
    loading: false,
    challenge: {
        type: 'PASSWORD_RESET',
        res: action.res
    }
});

const authPasswordResetFail = (state, action) => ({
    ...state,
    error: getErrorMessage(action.error),
    loading: false
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);

        case actionTypes.AUTH_LOGOUT_START:
            return authLogoutStart(state, action);
        case actionTypes.AUTH_LOGOUT_SUCCESS:
            return authLogoutSuccess(state, action);
        case actionTypes.AUTH_LOGOUT_FAIL:
            return authLogoutFail(state, action);

        case actionTypes.AUTH_CHALLENGE_NEW_PASSWORD_START:
            return authChallengeNewPasswordStart(state, action);
        case actionTypes.AUTH_CHALLENGE_NEW_PASSWORD_FAIL:
            return authChallengeNewPasswordFail(state, action);

        case actionTypes.AUTH_PASSWORD_RESET_START:
            return authPasswordResetStart(state, action);
        case actionTypes.AUTH_PASSWORD_RESET_SUCCESS:
            return authPasswordResetSuccess(state, action);
        case actionTypes.AUTH_PASSWORD_RESET_FAIL:
            return authPasswordResetFail(state, action);
        default:
            return state;
    }
};

export default reducer;

import React from 'react';
import { List, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';

import OrderListItem from './OrderListItem/OrderListItem';

const OrderList = props => {

    const renderOrderlist = () => (
        <List>
            {props.orders && props.orders.map((o, index) => (
                <OrderListItem
                    key={o.orderId}
                    order={o}
                    lastItem={index === props.orders.length - 1}
                />
            ))}
        </List>
    );

    const renderNoOrders = () => (
        <Typography variant="body2">Sie haben noch keinen digitalen Abrufschein erstellt.</Typography>
    );

    return props.orders && props.orders.length === 0 ? renderNoOrders() : renderOrderlist();
};

OrderList.propTypes = {
    orders: PropTypes.array
};

export default OrderList;

import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemIcon,
    ListItemText
} from '@material-ui/core';
import { Assignment as AssignmentIcon, Power as PowerIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';

const SelectOrderTypeDialog = props => (
    <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Neuer Abrufschein</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Bitte wählen Sie den Abrufschein, den Sie ausfüllen möchten.
            </DialogContentText>
            <List>
                <ListItem button component={Link} to="/orders/new/accessories">
                    <ListItemIcon>
                        <AssignmentIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Zubehör"
                        secondary={<>
                            Der Abrufschein ist spätestens 8 Wochen vor laut Roadmap geplantem
                                Inbetriebnahmetermin auszufüllen.<br />Ein fehlender Abrufschein führt zu einer
                            Verschiebung des Inbetriebnahmetermins auf den nächsten freien Termin.
                        </>}
                    />
                </ListItem>
                <ListItem button component={Link} to="/orders/new/hardware">
                    <ListItemIcon>
                        <PowerIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Hardware"
                        secondary={<>Der Abrufschein ist spätestens 6 Wochen vor laut Roadmap geplantem Inbetriebnahmetermin auszufüllen.<br />Ein fehlender Abrufschein führt zu einer Verschiebung des Inbetriebnahmetermins auf den nächsten freien Termin.</>}
                    />
                </ListItem>
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} color="primary">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

SelectOrderTypeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

export default SelectOrderTypeDialog;

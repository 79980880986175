import * as formTypes from './form-types';

const exampleYear = new Date().getFullYear() + 1;

const createAccessoriesForm = () => {
    return [
        {
            label: 'Ansprechpartner',
            name: 'contact',
            elements: {
                contact: {
                    elementType: 'textarea',
                    name: 'contactPerson',
                    value: '',
                    label: 'Technischer Ansprechpartner sowie eine Vertretung',
                    helpText: 'Name, Telefonnummer, E-Mail',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            }
        },
        {
            label: 'Lieferinformationen',
            name: 'delivery',
            elements: {
                address: {
                    elementType: 'textarea',
                    name: 'address',
                    value: '',
                    label: 'Lieferadresse',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                forkLift: {
                    elementType: 'select',
                    name: 'forkliftAvailable',
                    value: '',
                    label: 'Bei DC-Ladestationen: Ist am Standort ein Hubwagen bzw. Gabelstapler vorhanden? ',
                    helpText: 'Bei DC-Ladestation: Hiermit bestätigen wir, dass an unserem Standort sowohl bei Anlieferung als auch dem späteren Installationstermin der Hardware ein Hubwagen bzw. Gabelstapler vorhanden ist, der mindestens eine Tragkraft von 1000 kg hat. Bei Anlieferung der DC-Ladestation wird diese von der Spedition nur an das Institut geliefert. Ein Transport an den Aufbewahrungsstandort ist durch das jeweilige Institut durchzuführen. Am Installationstermin oder einen Tag vorher ist die Ladestation vom Aufbewahrungsstandort an den Installationsstandort zu transportieren. Die Erfahrung hat gezeigt, dass der Transport durch das Institut logistisch zu bevorzugen ist.',
                    options: [
                        { value: '', label: 'Bitte wählen' },
                        { value: 'Ja, mit einer Tragkraft von mindestens 1000 kg', label: 'Ja, mit einer Tragkraft von mindestens 1000 kg' },
                        { value: 'Es werden keine DC-Ladestationen abgerufen', label: 'Es werden keine DC-Ladestationen abgerufen' }
                    ],
                    valid: false,
                    validation: {
                        required: true
                    },
                    touched: false
                }
            }
        },
        {
            label: 'Zeitliche Planung',
            name: 'timePlanning',
            elements: {
                deliveryDate: {
                    elementType: 'text',
                    name: 'deliveryDate',
                    value: '',
                    label: 'Gewünschter Liefertermin für Zubehör',
                    helpText: 'Die Mitteilung muss mindestens 8 Wochen vor Installationstermin geschehen, da das Zubehör zur Fertigstellung der technischen Vorbereitungsmaßnahmen benötigt wird.',
                    placeholder: `z. B. KW 29 ${exampleYear}`,
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                preperationDate: {
                    elementType: 'text',
                    name: 'preparationDate',
                    value: '',
                    label: 'Zu welchem Zeitpunkt sind die bauseitigen Vorbereitungsarbeiten abgeschlossen?',
                    helpText: 'Nach Abschluss der Vorbereitungsarbeiten sowie bei einer Verzögerung ist EWE Go zu informieren.',
                    placeholder: `z. B. KW 21 ${exampleYear}`,
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
            }
        },
        {
            label: 'Hardwarezubehör',
            name: 'form',
            form: formTypes.ACCESSORIES,
            positions: [],
            touched: false,
            valid: false,
        }
    ];
};

const createHardwareForm = () => {
    return [
        {
            label: 'Ansprechpartner',
            name: 'contact',
            elements: {
                contact: {
                    elementType: 'textarea',
                    name: 'contactPerson',
                    value: '',
                    label: 'Technischer und netzwerktechnischer Ansprechpartner sowie jeweils ein Vertreter am Standort',
                    helpText: 'Name, Telefonnummer, E-Mail. <br /><br /><strong>Hinweis:</strong> Ansprechpartner hat Kenntnisse über die anstehenden Bauleistungen und Zugang zu Verteilerschränken, Unterverteilungen und vorbereiteten Elektroinstallationen. Weiterhin ist zu gewährleisten, dass dieser Ansprechpartner am Installationstermin vor Ort und dauerhaft erreichbar ist.',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            }
        },
        {
            label: 'Lieferinformationen',
            name: 'delivery',
            elements: {
                address: {
                    elementType: 'textarea',
                    name: 'address',
                    value: '',
                    label: 'Lieferadresse',
                    validation: {
                        required: true
                    },
                    helpText: 'Standortadresse für die Installation',
                    valid: false,
                    touched: false
                },
                deliveryAddress: {
                    elementType: 'textarea',
                    name: 'deliveryAddress',
                    value: '',
                    label: 'Ggfs. abweichende Lieferadresse',
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                addressAccessible: {
                    elementType: 'select',
                    name: 'addressAccessible',
                    value: '',
                    label: 'Ist ein Zugang zum Standort, an dem die Ladeinfrastruktur installiert werden soll, uneingeschränkt möglich?',
                    helpText: 'z.B. Zufahrt mit LKW bei für Installation DC-Ladestation',
                    options: [
                        { value: '', label: 'Bitte wählen' },
                        { value: 'Ja', label: 'Ja' },
                        { value: 'Nein', label: 'Nein' }
                    ],
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                addressAccessibleInfo: {
                    elementType: 'textarea',
                    name: 'addressAccessibleInfo',
                    value: '',
                    label: 'Falls die Lieferung eingeschränkt ist: Wie kann die Ware dennoch angeliefert werden?',
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                forkLift: {
                    elementType: 'select',
                    name: 'forkliftAvailable',
                    value: '',
                    label: 'Bei DC-Ladestationen: Ist am Standort ein Hubwagen bzw. Gabelstapler vorhanden? ',
                    helpText: 'Bei DC-Ladestation: Hiermit bestätigen wir, dass an unserem Standort sowohl bei Anlieferung als auch dem späteren Installationstermin der Hardware ein Hubwagen bzw. Gabelstapler vorhanden ist, der mindestens eine Tragkraft von 1000 kg hat. Bei Anlieferung der DC-Ladestation wird diese von der Spedition nur an das Institut geliefert. Ein Transport an den Aufbewahrungsstandort ist durch das jeweilige Institut durchzuführen. Am Installationstermin oder einen Tag vorher ist die Ladestation vom Aufbewahrungsstandort an den Installationsstandort zu transportieren. Die Erfahrung hat gezeigt, dass der Transport durch das Institut logistisch zu bevorzugen ist.',
                    options: [
                        { value: '', label: 'Bitte wählen' },
                        { value: 'Ja, mit einer Tragkraft von mindestens 1000 kg', label: 'Ja, mit einer Tragkraft von mindestens 1000 kg' },
                        { value: 'Es werden keine DC-Ladestationen abgerufen', label: 'Es werden keine DC-Ladestationen abgerufen' }
                    ],
                    valid: false,
                    validation: {
                        required: true
                    },
                    touched: false
                },
                disposePacking: {
                    elementType: 'select',
                    name: 'disposePacking',
                    value: '',
                    label: 'Besteht die Möglichkeit am Standort die Verpackung der Ladestationen zu entsorgen? ',
                    helpText: 'Die AC-Ladestationen sind in Pappe verpackt und haben eine Verpackungsgröße von 2m². Die DC-Ladestation ist mit Sperrholz verpackt mit einer Verpackungsgröße von 5m².',
                    options: [
                        { value: '', label: 'Bitte wählen' },
                        { value: 'Nein', label: 'Nein' },
                        { value: 'Nur Pappe', label: 'Nur Pappe' },
                        { value: 'Nur Sperrholz', label: 'Nur Sperrholz' },
                        { value: 'Pappe und Sperrholz', label: 'Pappe und Sperrholz' },
                    ],
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                approvalRequired: {
                    elementType: 'select',
                    name: 'approvalRequired',
                    value: '',
                    label: 'Genehmigung über den Zugang zum Standort für den Aufbaudienstleister erforderlich?',
                    options: [
                        { value: '', label: 'Bitte wählen' },
                        { value: 'Ja', label: 'Ja' },
                        { value: 'Nein', label: 'Nein' }
                    ],
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                approvalContact: {
                    elementType: 'textarea',
                    name: 'approvalContact',
                    value: '',
                    label: 'Falls erforderlich: Ansprechpartner zu Erhalt der Genehmigung',
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
            }
        },
        {
            label: 'Zeitliche Planung',
            name: 'timePlanning',
            elements: {
                preparationDone: {
                    elementType: 'select',
                    name: 'preparationDone',
                    value: '',
                    label: 'Wurde der Abrufschein zum Hardwarezubehör ausgefüllt?',
                    helpText: 'Die Mitteilung muss mindestens 8 Wochen vor Installationstermin geschehen, da das Zubehör zur Fertigstellung der technischen Vorbereitungsmaßnahmen benötigt wird.',
                    options: [
                        { value: '', label: 'Bitte wählen' },
                        { value: 'Ja', label: 'Ja' },
                        { value: 'Nein', label: 'Nein' }
                    ],
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                deliveryDate: {
                    elementType: 'text',
                    name: 'deliveryDate',
                    value: '',
                    label: 'Gewünschter Aufbautermin der Ladeinfrastruktur?',
                    helpText: 'Mitteilung an EWE Go muss mindestens 6 Wochen vor Wunschtermin erfolgen.',
                    placeholder: `z. B. KW 29 ${exampleYear}`,
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
            }
        },
        {
            label: 'Ladeinfrastruktur',
            name: 'form',
            form: formTypes.HARDWARE,
            positions: [],
            touched: false,
            valid: false,
        },
        {
            label: 'Anhang',
            name: 'attachments',
            elements: {
                photoLocation: {
                    elementType: 'file',
                    name: 'photoLocation',
                    value: '',
                    label: 'Foto Standort der geplanten Ladeinfrastruktur',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                sitePlan: {
                    elementType: 'file',
                    name: 'sitePlan',
                    value: '',
                    label: 'Lageplan (z.B. Google Maps) des Standortes inkl. Kennzeichnung der geplanten Ladeinfrastruktur',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            }
        }
    ];
};

const formFactory = formType => {
    switch (formType) {
        case formTypes.ACCESSORIES:
            return createAccessoriesForm();
        case formTypes.HARDWARE:
            return createHardwareForm();
        default:
            return null;
    }
};

export default formFactory;

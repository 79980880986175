import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, TextField, Button, Typography, Paper, LinearProgress } from '@material-ui/core';

import * as authOperations from '../../store/auth/operations';

import * as classes from './Signin.module.css';

class Signin extends Component {

    state = {
        forgotPassword: false,
        username: '',
        password: '',
        challengeCode: '',
    };

    renderSigninForm = () => (
        <form onSubmit={this.submitSigninHandler}>
            <Typography component="h1" variant="h5" align="center">Anmelden</Typography>
            { this.props.error && <Typography variant={'body2'} color="error">{this.props.error}</Typography> }

            <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                id="username"
                name="username"
                label="Benutzername"
                required={true}
                autoFocus={true}
                value={this.state.username}
                onChange={e => this.setState({ username: e.target.value })}
            />
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                id="password"
                name="password"
                label="Passwort"
                type="password"
                required={true}
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
            />
            <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                size="large"
                className={classes.SubmitButton}
                disabled={this.props.loading}
            >
                Anmelden
            </Button>
            <Typography color="primary" variant="body2" onClick={this.toggleForgotPasswortHandler}>Passwort vergessen?</Typography>
        </form>
    );

    renderForgotPasswordForm = () => (
        <form onSubmit={this.submitPasswordResetForm}>
            <Typography component="h1" variant="h5" align="center">Passwort zurücksetzen</Typography>
            { this.props.error && <Typography variant={'body2'} color="error">{this.props.error}</Typography> }

            <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                id="username"
                name="username"
                label="Benutzername"
                required={true}
                autoFocus={true}
                value={this.state.username}
                onChange={e => this.setState({ username: e.target.value })}
            />
            <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                size="large"
                className={classes.SubmitButton}
                disabled={this.props.loading}
            >
                Passwort zurücksetzen
            </Button>
            <Typography color="primary" variant="body2" onClick={this.toggleForgotPasswortHandler}>Zurück</Typography>
        </form>
    );

    renderNewPasswordForm = () => (
        <form onSubmit={this.submitNewPasswordHandler}>
            <Typography component="h1" variant="h5" align="center">Neues Passwort</Typography>
            { this.props.error && <Typography variant={'body2'} color="error">{this.props.error}</Typography> }

            { this.props.challengeCodeRequired &&
                <Typography variant="body2">Bitte geben Sie den Bestätigungscode ein, der an die folgende E-Mail-Adresse versendet wurde: {this.props.challenge.res.CodeDeliveryDetails.Destination}</Typography>
            }

            <TextField
                variant="outlined"
                margin="normal"
                fullWidth={true}
                id="password"
                name="password"
                label="Passwort"
                type="password"
                required={true}
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
            />
            {
                this.props.challengeCodeRequired &&
                (
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth={true}
                        id="challengeCode"
                        name="challengeCode"
                        label="Bestätigungscode"
                        type="number"
                        required={true}
                        value={this.state.challengeCode}
                        onChange={e => this.setState({ challengeCode: e.target.value })}
                    />
                )
            }
            <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                size="large"
                className={classes.SubmitButton}
                disabled={this.props.loading}
            >
                Passwort festlegen
            </Button>
        </form>
    );

    toggleForgotPasswortHandler = () => {
        this.setState(prevState => ({ forgotPassword: !prevState.forgotPassword }));
    };

    submitSigninHandler = event => {
        event.preventDefault();

        this.setState({password: ''});
        this.props.onSignin(this.state.username, this.state.password);
    };

    submitPasswordResetForm = event => {
        event.preventDefault();

        this.props.onResetPassword(this.state.username);
    };

    submitNewPasswordHandler = event => {
        event.preventDefault();

        this.props.onCompleteNewPasswordChallenge(this.props.challenge, this.state.password, this.state.username, this.state.challengeCode);
        this.setState({password: ''});
    };

    render() {
        if (this.props.user) {
            let { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.replace(from);
            return null;
        }

        let form;
        if (this.props.challenge) {
            form = this.renderNewPasswordForm();
        } else if (this.state.forgotPassword) {
            form = this.renderForgotPasswordForm();
        } else {
            form = this.renderSigninForm();
        }

        return (
            <Container maxWidth="sm">
                <Paper className={classes.Signin}>
                    { this.props.loading && <LinearProgress variant="query" /> }

                    <div className={classes.Content}>
                        { form }
                    </div>
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    loading: state.auth.loading,
    error: state.auth.error,
    challenge: state.auth.challenge,
    challengeCodeRequired: state.auth.challenge && state.auth.challenge.type === 'PASSWORD_RESET'
});

const mapDispatchToProps = dispatch => ({
    onSignin: (username, password) => dispatch(authOperations.signin(username, password)),
    onCompleteNewPasswordChallenge: (challenge, password, username, challengeCode) => dispatch(authOperations.completeNewPasswordChallenge(challenge, password, username, challengeCode)),
    onResetPassword: username => dispatch(authOperations.resetPassword(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);

import { connect } from "react-redux";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";

const mapStateToProps = ({ auth }) => ({
  auth: {
    isAuthenticated: auth.user !== null,
    loading: auth.loading
  } 
});

export default connect(mapStateToProps)(PrivateRoute);
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#324042'
        },
        secondary: {
            main: '#FFF101'
        },
    },
    overrides: {
        MuiFormHelperText: {
            root: {
                color: 'rgba(0, 0, 0, 1)',
            }
          }
    }
});

export default theme;

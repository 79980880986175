import React from 'react';
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Divider } from '@material-ui/core';
import { Assignment as AssignmentIcon, FindInPage as FindInPageIcon, Power as PowerIcon } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const translateFormType = formType => {
  switch (formType) {
      case 'ACCESSORIES':
          return 'Zubehör';
      case 'HARDWARE':
          return 'Hardware';
      default:
          return 'Unknown FromType';
  }
};

const formatAddress = address => {
    if (!address) {
        return 'Keine Addressinformation';
    }

    return address.replace(/\n/g, ', ');
};

const OrderListItem = props => {
    const url = `/orders/${props.order.userId}/${props.order.orderId}`;

    return (
        <>
            <ListItem button component={Link} to={url}>
                <ListItemIcon>
                    {props.order.formType === 'HARDWARE' ? <PowerIcon/> : <AssignmentIcon/>}
                </ListItemIcon>
                <ListItemText
                    primary={translateFormType(props.order.formType) + ' - ' + props.order.createdAt.toLocaleDateString()}
                    secondary={formatAddress(props.order.address)}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Bearbeiten" component={Link} to={url}>
                        <FindInPageIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            { !props.lastItem && <Divider/> }
        </>
    );
};

OrderListItem.propTypes = {
    orders: PropTypes.object,
    lastItem: PropTypes.bool
};

export default OrderListItem;

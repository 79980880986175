export const ORDERS_LIST_START = 'ORDERS_LIST_START';
export const ORDERS_LIST_SUCCESS = 'ORDERS_LIST_SUCCESS';
export const ORDERS_LIST_FAIL = 'ORDERS_LIST_FAIL';

export const NEW_ORDER_START = 'ORDERS_NEW_ORDER_START';
export const NEW_ORDER_SUCCESS = 'ORDERS_NEW_ORDER_SUCCESS';
export const NEW_ORDER_FAIL = 'ORDERS_NEW_ORDER_FAIL';

export const CLOSE_ORDER_FORM = 'CLOSE_ORDER_FORM';

export const ORDER_GET_START = 'ORDER_GET_START';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_FAIL = 'ORDER_GET_FAIL';

export const ORDER_GET_ATTACHMENT_START = 'ORDER_GET_ATTACHMENT_START';
export const ORDER_GET_ATTACHMENT_SUCCESS = 'ORDER_GET_ATTACHMENT_SUCCESS';
export const ORDER_GET_ATTACHMENT_FAIL = 'ORDER_GET_ATTACHMENT_FAIL';

import * as actionTypes from './actionTypes';
import { getErrorMessage } from '../../utils/error-utils';

const initialState = {
    error: null,
    loading: false,

    order: null,
    orderList: null,

    formData: null,
    newOrderCreated: false,
};


const ordersListStart = (state, action) => ({
    ...state,
    error: null,
    orderList: null,
    loading: true
});

const ordersListSuccess = (state, action) => ({
    ...state,
    orderList: action.orderList,
    loading: false
});

const ordersListFail = (state, action) => ({
    ...state,
    error: getErrorMessage(action.error),
    orderList: null,
    loading: false
});

const newOrderStart = (state, action) => ({
    ...state,
    formData: action.formData,
    loading: true,
    error: null,
    newOrderCreated: false,
});

const newOrderSuccess = (state, action) => ({
    ...state,
    formData: null,
    loading: false,
    newOrderCreated: true,
});

const newOrderFail = (state, action) => ({
    ...state,
    loading: false,
    error: getErrorMessage(action.error)
});

const closeOrderForm = (state, action) => ({
    ...state,
    newOrderCreated: false,
});

const orderGetStart = (state, action) => ({
    ...state,
    order: null,
    loading: true,
    error: null
});

const orderGetSuccess = (state, action) => ({
    ...state,
    order: action.order,
    loading: false
});

const orderGetFail = (state, action) => ({
    ...state,
    loading: false,
    error: getErrorMessage(action.error)
});

const orderGetAttachmentStart = (state, action) => ({
    ...state,
    loading: true,
    error: null,
});

const orderGetAttachmentSuccess = (state, action) => ({
    ...state,
    loading: false,
});

const orderGetAttachmentFail = (state, action) => ({
    ...state,
    loading: true,
    error: getErrorMessage(action.error),
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ORDERS_LIST_START:
            return ordersListStart(state, action);
        case actionTypes.ORDERS_LIST_SUCCESS:
            return ordersListSuccess(state, action);
        case actionTypes.ORDERS_LIST_FAIL:
            return ordersListFail(state, action);

        case actionTypes.NEW_ORDER_START:
            return newOrderStart(state, action);
        case actionTypes.NEW_ORDER_SUCCESS:
            return newOrderSuccess(state, action);
        case actionTypes.NEW_ORDER_FAIL:
            return newOrderFail(state, action);
        case actionTypes.CLOSE_ORDER_FORM:
            return closeOrderForm(state, action);

        case actionTypes.ORDER_GET_START:
            return orderGetStart(state, action);
        case actionTypes.ORDER_GET_SUCCESS:
            return orderGetSuccess(state, action);
        case actionTypes.ORDER_GET_FAIL:
            return orderGetFail(state, action);

        case actionTypes.ORDER_GET_ATTACHMENT_START:
            return orderGetAttachmentStart(state, action);
        case actionTypes.ORDER_GET_ATTACHMENT_SUCCESS:
            return orderGetAttachmentSuccess(state, action);
        case actionTypes.ORDER_GET_ATTACHMENT_FAIL:
            return orderGetAttachmentFail(state, action);
        default:
            return state;
    }
};

export default reducer;

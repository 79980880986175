import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, FormControl, Select,
         InputLabel, FormControlLabel, FormGroup, FormLabel, Checkbox } from '@material-ui/core';
import * as PropTypes from 'prop-types';

const AddAccessoryDialog = props => {
    const resetForm = () => ({
        ac: [
            { label: 'Erdstück', checked: false },
            { label: 'Stele/Standfuß + KÜK*', checked: false },
            { label: 'Anfahrschutz**', checked: false }
        ],
        dc: [
            { label: 'Fertigfundament', checked: false },
            { label: 'Anfahrschutz**', checked: false }
        ]
    });

    const [ type, setType ] = useState('');
    const [ accessories, setAccessories ] = useState(resetForm());

    const changeAccessoryHandler = (event, accessory) => {
        const newAccessories = {ac: [...accessories.ac], dc: [...accessories.dc]};
        const index = newAccessories[type].findIndex(a => a.label === accessory.label);

        newAccessories[type][index] = {
            ...accessory,
            checked: !accessory.checked,
        };

        setAccessories(newAccessories);
    };

    const formIsValid = () => {
        return type && !!accessories[type].find(a => a.checked);
    };

    const submitHandler = () => {
        const formData = {
            type,
            accessories: accessories[type].filter(a => a.checked).map(a => a.label)
        };

        props.onSubmit(formData);
        setType('');
        setAccessories(resetForm());
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>

            <DialogTitle>Zubehör hinzufügen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bitte geben Sie an, welches Zubehör Sie abrufen möchten.
                </DialogContentText>

                <FormControl fullWidth required style={{marginBottom: '1.5rem'}}>
                    <InputLabel id="type">Art der Ladesäule:</InputLabel>
                    <Select
                        labelId="type"
                        autoWidth={false}
                        value={type}
                        onChange={e => setType(e.target.value)}
                    >
                        <MenuItem value="">Bitte wählen</MenuItem>
                        {Object.keys(accessories).map(type => <MenuItem key={type} value={type}>{type.toUpperCase()}</MenuItem>)}
                    </Select>
                </FormControl>

                {type && (
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Verfügbares Zubehör</FormLabel>
                        {accessories[type].map(accessory => (
                            <FormGroup key={accessory.label}>
                                <FormControlLabel
                                    control={<Checkbox value={accessory.label} checked={accessory.checked} onChange={event => changeAccessoryHandler(event, accessory)} />}
                                    label={accessory.label}
                                />
                            </FormGroup>
                        ))}
                    </FormControl>
                )}

                {type === 'ac' && (
                        <p>* KÜK = Kabelübergangskasten, welcher in der Stele montiert ist.</p>
                )}

                { type && <p>** Soll die Ladestation von beiden Seiten angefahren werden muss der Anfahrschutz zweimal bestellt werden.</p> }
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={props.onClose} color="default">Schließen</Button>
                <Button type="button" onClick={submitHandler} color="primary" disabled={!formIsValid()}>Speichern</Button>
            </DialogActions>
        </Dialog>
    )
};

AddAccessoryDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default AddAccessoryDialog;

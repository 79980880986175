import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Button, Tooltip, Drawer, Divider, List, ListItem, ListItemIcon,
         ListItemText } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle as AccountCircleIcon, Home as HomeIcon, Assignment as AssignmentIcon,
         ExitToApp as ExitToAppIcon, Help as HelpIcon, Power as PowerIcon, Lock as LockIcon } from '@material-ui/icons';

import LinearPageProgress from '../LinearPageProgress/LinearPageProgress';

import * as authOperations from '../../store/auth/operations';

import * as classes from './Navigation.module.css';
import EWEGoLogo from './logo.png';
import LogoDark from './logo-dark.png';

const Navigation = props => {

    const [ redirect, setRedirect ] = useState(null);
    const [ showDrawer, setShowDrawer ] = useState(false);

    const navItems = [
        [
            { name: 'Startseite', icon: <HomeIcon/>, url: '/' },
        ],
        [
            { name: 'Abrufschein Zubehör', icon: <AssignmentIcon/>, url: '/orders/new/accessories' },
            { name: 'Abrufschein Hardware', icon: <PowerIcon/>, url: '/orders/new/hardware' },
        ],
        [
            { name: 'Abmelden', icon: <ExitToAppIcon/>, onClick: () => props.onLogout() },
        ]
    ];

    const navItemsLoggedOut = [
        [
            { name: 'Startseite', icon: <HomeIcon/>, url: '/' },
        ]
    ];

    const bottomNavItems = [
        [
            { name: 'Impressum', icon: <HelpIcon/>, onClick: () => window.open('https://www.ewe-go.de/impressum/', '_blank') },
            { name: 'Datenschutz', icon: <LockIcon/>, onClick: () => window.open('https://www.ewe-go.de/datenschutzerklaerung/', '_blank') },
        ]
    ];

    const logoutHandler = () => {
        props.onLogout();
    };

    const signinHandler = () => {
        setRedirect('/signin');
    };

    const toggleDrawerHandler = () => {
        setShowDrawer(!showDrawer);
    };

    const renderMenuItem = (navItem) => {
        const clickHandler = !navItem.onClick ? toggleDrawerHandler : () => {
            toggleDrawerHandler();
            navItem.onClick();
        };

        return (
            <ListItem button key={navItem.name} component={Link} to={navItem.url || ""} onClick={clickHandler}>
                <ListItemIcon>{navItem.icon}</ListItemIcon>
                <ListItemText primary={navItem.name}/>
            </ListItem>
        );
    };

    const renderMenu = (navItems, key) => (
        <List>
            {navItems.map((group, index) => (
                <div key={`${key}-${index}`}>
                    {group.map(navItem => (
                        renderMenuItem(navItem)
                    ))}
                    {index < navItems.length - 1 && <Divider/>}
                </div>
            ))}
        </List>
    );

    const accountNav = props.user ? (
        <>
            <Tooltip title={props.user.email + (props.account  && props.account.roles && props.account.roles.includes('admin') ? ' (Admin)' : '')} >
                <IconButton
                    aria-label="Account"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="secondary"
                >
                    <AccountCircleIcon/>
                </IconButton>
            </Tooltip>
            <Button color="secondary" onClick={logoutHandler}>Abmelden</Button>
        </>
    ) : <Button color="secondary" onClick={signinHandler}>Anmelden</Button>;

    return (
        <>
            { redirect && <Redirect to={redirect} /> }
            { ( props.authLoading || props.ordersLoading ) && <LinearPageProgress variant="query" color="secondary" /> }
            <AppBar position="static" className={classes.AppBar} color="primary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawerHandler}>
                        <MenuIcon/>
                    </IconButton>
                    <div className={classes.LogoWrapper}>
                        <img src={EWEGoLogo} alt="EWE Go" className={classes.Logo} />
                    </div>
                    {accountNav}
                </Toolbar>
            </AppBar>

            <Drawer
                variant="temporary"
                open={showDrawer}
                onClose={toggleDrawerHandler}
                classes={{
                    paper: classes.Drawer
                }}
            >
                <div className={classes.DrawerContent}>
                    <div>
                        <div className={classes.DrawerLogoWrapper}>
                            <img src={LogoDark} alt="EWE Go" className={classes.DrawerLogo} />
                        </div>

                        {props.user ? renderMenu(navItems, 'main') : renderMenu(navItemsLoggedOut, 'main')}
                    </div>

                    {renderMenu(bottomNavItems, 'bottom')}
                </div>
            </Drawer>
        </>
    );
};

const mapStateToProps = state => ({
    account: state.auth.account,
    user: state.auth.user,
    authLoading: state.auth.loading,
    ordersLoading: state.orders.loading,
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => dispatch(authOperations.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import './index.css';
import 'typeface-roboto';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureAmplify } from './config/amplify';

import authReducer from './store/auth/reducer';
import orderReducer from './store/orders/reducer';
import { restoreSession } from './store/auth/operations';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // Redux Dev Tools

const rootReducer = combineReducers({
    auth: authReducer,
    orders: orderReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

configureAmplify();

// must be called before 1st render to set auth state
store.dispatch(restoreSession());

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

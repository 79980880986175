import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({
    component: Component,
    auth: { 
      isAuthenticated,
      loading
    },
    path
 }) => (
    <Route
      path={path}
      render={props =>
        !isAuthenticated && !loading ? (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

export default PrivateRoute;
import React, { Component } from 'react';
import { Paper, Typography, Button, Stepper, Step, StepLabel } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';

import * as ordersOperations from '../../store/orders/operations';
import { extractDataFromForm, extractAttachmentsFromForm } from '../../utils/form-utils';
import * as classes from './OrderForm.module.css';
import formFactory from '../../utils/form-factory';
import * as formTypes from '../../utils/form-types';
import FormInput from '../../components/FormInput/FormInput';
import AccessoriesForm from './AccessoriesForm/AccessoriesForm';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import HardwareForm from './HardwareForm/HardwareForm';
import AttachmentList from '../../components/AttachmentList/AttachmentList';

class OrderForm extends Component {

    state = {
        formType: null,
        form: null,
        activeStep: 0,
        confirmationForm: false,
        autoFocus: true
    };

    loadForm = () => {
        switch (this.props.match.params.formType.toUpperCase()) {
            case formTypes.ACCESSORIES:
                return this.setState({
                    formType: formTypes.ACCESSORIES,
                    form: formFactory(formTypes.ACCESSORIES),
                    autoFocus: true,
                    activeStep: 0,
                });
            case formTypes.HARDWARE:
                return this.setState({
                    formType: formTypes.HARDWARE,
                    form: formFactory(formTypes.HARDWARE),
                    autoFocus: true,
                    activeStep: 0,
                });
            default:
                this.setState({formTypes: null, form: null, activeStep: 0});
        }
    };

    componentDidMount() {
        this.loadForm();
    }

    /**
     * Handle autofocus for form inputs.
     *
     * @param {bool} autoFocus
     */
    setAutoFocus = autoFocus => {
        this.setState({ autoFocus });
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeStep } = this.state;

        // reset autofocus if step changed
        if (prevState.activeStep !== activeStep) {
            this.setAutoFocus(true);
        }

        if (this.props.location !== prevProps.location) {
            this.loadForm();
        }
    }

    getFormNameAndDescription = () => {
        const translation = {
            ACCESSORIES: {
                formName: 'Zubehör',
                description: 'Gegenstand dieses Dokumentes ist die notwendige Informationsbeschaffung für eine Bestellung (Abrufbestellung) von Zubehör im Rahmen des Projektes LamA. Die jeweilige Abrufbestellung bezieht sich auf ein zuvor vereinbartes Kontingent je Hardwareprodukt.'
            },
            HARDWARE: {
                formName: 'Hardware',
                description: 'Gegenstand dieses Dokumentes ist die notwendige Informationsbeschaffung für eine Bestellung (Abrufbestellung) von Ladeinfrastruktur im Rahmen des Projektes LamA. Die jeweilige Abrufbestellung bezieht sich auf ein zuvor vereinbartes Kontingent je Hardwareprodukt.'
            }
        };

        return translation[this.state.formType];
    };

    validateInput = element => {
        if (!element.validation) {
            return true;
        }

        // check with validator if exists
        if(element.validation.required && typeof element.validation.validator === 'function') {
            return element.validation.validator(element.value);
        }

        return !element.validation.required || (!!element.value || !!element.file);
    };

    inputChangeHandler = (event, groupIndex, formKey) => {
        const updatedForm = [...this.state.form];
        const updatedGroup = {...updatedForm[groupIndex]};
        const updatedElements = {...updatedGroup.elements};
        const updatedElement = {...updatedElements[formKey]};

        if (updatedElement.elementType === 'file') {
            if (event.target.files && event.target.files.length === 1) {
                updatedElement.file = event.target.files[0];
            } else {
                updatedElement.file = null;
            }
        } else {
            updatedElement.value = event.target.value;
        }

        updatedElement.valid = this.validateInput(updatedElement);
        updatedElement.touched = true;

        updatedElements[formKey] = updatedElement;
        updatedGroup.elements = updatedElements;
        updatedForm[groupIndex] = updatedGroup;
        this.setState({form: updatedForm, autoFocus: false});
    };

    orderFormInputHandler = orderData => {
        const updatedForm = [...this.state.form];
        const updatedGroup = {...updatedForm[this.state.activeStep]};
        const updatedPositions = [...updatedGroup.positions];

        updatedPositions.push(orderData);

        updatedGroup.positions = updatedPositions;
        updatedForm[this.state.activeStep] = updatedGroup;
        this.setState({form: updatedForm});
    };

    orderFormRemoveHandler = index => {
        const updatedForm = [...this.state.form];
        const updatedGroup = {...updatedForm[this.state.activeStep]};
        const updatedPositions = [...updatedGroup.positions];

        updatedPositions.splice(index, 1);

        updatedGroup.positions = updatedPositions;
        updatedForm[this.state.activeStep] = updatedGroup;
        this.setState({form: updatedForm});
    };

    formGroupIsValid = groupIndex => {
        const formGroup = this.state.form[groupIndex];

        if (formGroup.elements) { // Form Inputs
            for (const key in formGroup.elements) {
                const element = formGroup.elements[key];
                if (!element.valid) {
                    return false;
                }
            }
        } else if (formGroup.positions) { // Order Table
            return formGroup.positions.length > 0;
        }

        return true;
    };

    formIsValid = () => {
        for (let i = 0; i < this.state.form.length; i++) {
            if (!this.formGroupIsValid(i)) {
                return false;
            }
        }
        return true;
    };

    submitHandler = event => {
        event.preventDefault();
    };

    submitOrderHandler = event => {
        event.preventDefault();

        this.props.onOrder(this.state.formType, this.state.form);
    };

    cancelConfirmationHandler = () => {
        this.setState({confirmationForm: false});
    };

    nextStepHandler = () => {
        this.setState(prevState => ({activeStep: prevState.activeStep + 1}));
    };

    prevStepHandler = () => {
        this.setState(prevState => ({activeStep: prevState.activeStep - 1}));
    };

    renderForm = () => {
        const { autoFocus, activeStep, form } = this.state;

        const formGroups = [];

        for (const group of form) {
            const fg = {
                label: group.label,
                formElements: [],
                form: group.form,
                positions: group.positions,
            };

            for (const key in group.elements) {
                fg.formElements.push({
                    id: key,
                    config: group.elements[key]
                });
            }
            formGroups.push(fg);
        }

        const formGroup = formGroups[activeStep];
        const stepper = (
            <Stepper className={classes.Stepper} activeStep={activeStep}>
                {formGroups.map((group, groupIndex) => (
                    <Step key={group.label} completed={activeStep > groupIndex}>
                        <StepLabel>{group.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        );

        return (
            <form onSubmit={this.submitHandler}>
                {stepper}

                {formGroup.formElements > 0 &&
                <Typography variant="subtitle1" gutterBottom>{formGroup.label}</Typography>}
                {formGroup.formElements.map((e, index) => <FormInput
                    key={e.id}
                    id={e.id}
                    elementType={e.config.elementType}
                    label={e.config.label}
                    value={e.config.value}
                    changed={event => this.inputChangeHandler(event, activeStep, e.id)}
                    placeholder={e.config.placeholder}
                    helpText={e.config.helpText}
                    required={e.config.validation && e.config.validation.required}
                    options={e.config.options}
                    autoFocus={autoFocus && index === 0} // autoFocus for first element only,
                    inputProps={{...e.config.inputProps || {}}}
                />)}

                {formGroup.form === formTypes.ACCESSORIES && (
                    <>
                        <AccessoriesForm
                            label={formGroup.label}
                            positions={formGroup.positions}
                            onAddOrderPosition={this.orderFormInputHandler}
                            onRemoveOrderPosition={this.orderFormRemoveHandler}
                        />
                        Hinweis: Für jede Ladestation ist das Zubehör separat anzugeben.
                    </>
                )
                }

                {formGroup.form === formTypes.HARDWARE &&
                <HardwareForm
                    label={formGroup.label}
                    positions={formGroup.positions}
                    onAddOrderPosition={this.orderFormInputHandler}
                    onRemoveOrderPosition={this.orderFormRemoveHandler}
                />
                }

                <div className={classes.ButtonWrapper}>
                    <sub style={{float: 'left', marginTop: '8px'}}>* Pflichtfeld</sub>
                    {
                        activeStep === 0 ?
                            <Button variant="contained" color="default" type="button" component={Link}
                                    to="/">Abbrechen</Button>
                            : <Button variant="contained" color="default" type="button"
                                      onClick={this.prevStepHandler}>Zurück</Button>
                    }
                    {
                        activeStep < form.length - 1 ?
                            <Button variant="contained" color="primary" type="button"
                                    disabled={!this.formGroupIsValid(activeStep)}
                                    onClick={this.nextStepHandler}>Weiter</Button>
                            : <Button variant="contained" color="primary" type="submit"
                                    onClick={ () => this.setState({ confirmationForm: true })}
                                    disabled={!this.formIsValid()}>Prüfen</Button>
                    }
                </div>
            </form>
        );
    };

    confirmationForm = () => {
        if (this.props.newOrderCreated) {
            this.props.onCloseOrderForm();
            return <Redirect to="/" />;
        }

        const handleDownloadAttachment = attachment => {
            const url = URL.createObjectURL(attachment);
            window.open(url, '_blank');
        }

        return (
            <div className={classes.ConfirmationForm}>
                <form onSubmit={this.submitOrderHandler}>
                    <Typography variant="h5" component="h2">Zusammenfassung</Typography>
                    <Typography variant="body2">Bitte prüfen Sie Ihren Abruf gründlich, bevor Sie den Abrufschein verbindlich absenden.</Typography>

                    <OrderSummary
                        formData={extractDataFromForm(this.state.form)}
                    />

                    <AttachmentList onDownload={handleDownloadAttachment} attachments={extractAttachmentsFromForm(this.state.form)} />

                    {this.state.formType === formTypes.HARDWARE && extractAttachmentsFromForm(this.state.form).length === 0 && (
                      <Typography variant="body2" color="error" gutterBottom={true}>Hinweis: Lageplan und Fotos wurden nicht hochgeladen</Typography>
                    )}

                    {this.props.error && <Typography variant="body2" color="error">Bei der Bestellung ist ein Fehler
                        aufgetreten: {this.props.error}</Typography>}

                    <Typography variant="body2" className={classes.ConfirmationText}>Durch das Klicken auf die Schaltfläche "Bestellen" bestätige Sie die Vollständigkeit und Richtigkeit der Angaben.</Typography>
                    <Typography variant="subtitle2" className={classes.ConfirmationText}>Bitte prüfen Sie die Ware bei Erhalt auf Ihre Vollständigkeit und Unversehrtheit, bevor Sie den Erhalt der Ware quittieren.</Typography>
                    <div className={classes.ButtonWrapper}>
                        <Button variant="contained" color="default" type="button" onClick={this.cancelConfirmationHandler}
                                disabled={this.props.loading}>Zurück</Button>
                        <Button variant="contained" color="primary" type="submit"
                                disabled={this.props.loading}>Bestellen</Button>
                    </div>
                </form>
            </div>
        );
    };


    render() {
        if (!this.state.formType) {
            return <p>Das Formular wird geladen...</p>;
        }

        const {formName, description} = this.getFormNameAndDescription();

        return (
          <>
              <Prompt
                when={!this.state.confirmationForm}
                message="Sie haben Ihre Änderungen noch nicht versendet. Möchten Sie wirklich die Seite verlassen?"
              />

              <div className={classes.Container}>
                    <Paper className={classes.Info}>
                        <Typography variant="h5" component="h1" gutterBottom>Abrufschein Projekt LamA
                            - {formName}</Typography>
                        {description}
                    </Paper>

                    <div className={classes.FormWrapper}>
                        <Paper className={classes.Form}>
                            {!this.state.confirmationForm ? this.renderForm() : this.confirmationForm()}
                        </Paper>
                    </div>
                </div>
          </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    loading: state.orders.loading,
    error: state.orders.error,
    newOrderCreated: state.orders.newOrderCreated
});

const mapDispatchToProps = dispatch => ({
    onOrder: (formType, form) => {
        dispatch(ordersOperations.createOrder(formType, form));
    },
    onCloseOrderForm: () => {
        dispatch(ordersOperations.closeOrderForm());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);

import Amplify, { Auth } from 'aws-amplify';

import awsExport from './amplify-exports';

const dev = {
    cognito: {
        REGION: awsExport.aws_cognito_region,
        USER_POOL_ID: awsExport.aws_user_pools_id,
        APP_CLIENT_ID: awsExport.aws_user_pools_web_client_id,
        IDENTITY_POOL_ID: awsExport.aws_cognito_identity_pool_id
    },
    apiGateway: {
        REGION: awsExport.aws_cloud_logic_custom[0].region,
        URL: awsExport.aws_cloud_logic_custom[0].endpoint
    }
};

const prod = {};

export const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export const configureAmplify = () => {
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        },
        API: {
            endpoints: [
                {
                    name: 'default',
                    endpoint: config.apiGateway.URL,
                    region: config.apiGateway.REGION,
                    custom_header: async () => {
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                },
            ]
        }
    });
};

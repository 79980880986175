import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { Email as EmailIcon } from '@material-ui/icons';

import * as classes from './Greeting.module.css';

const Greeting = () => (
    <Paper className={classes.Greeting}>
        <table className={classes.ContactTable}>
            <tbody>
            <tr>
                <th><EmailIcon/></th>
                <td><a href="mailto:fraunhofer@ewe-go.de">fraunhofer@ewe-go.de</a></td>
            </tr>
            </tbody>
        </table>

        <Typography variant="h5" component="h1" className={classes.WelcomeTitle} gutterBottom>Willkommen</Typography>
        <Typography variant="body1" gutterBottom>
            Dieses Tool dient zur Informationsbeschaffung für eine Abrufbestellung von Zubehör und Ladeinfrastruktur im Rahmen des Projektes LamA. Die jeweilige Abrufbestellung bezieht sich auf ein zuvor vereinbartes Kontingent je Hardwareprodukt.
        </Typography>
        <Typography variant="body1">
            Hinweis: Nach Eingang des Abrufscheins bei EWE Go erhalten Sie rechtzeitig vor Installation eine Bestätigungsmail mit dem entsprechenden Installationstermin.
        </Typography>
    </Paper>
);

export default Greeting;

import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Folder as FolderIcon } from '@material-ui/icons';
import filesize from 'filesize';

import * as classes from './AttachmentList.module.css';

const AttachmentList = props => {
    if (!props.attachments || props.attachments.length === 0) {
        return null;
    }

    return (
        <div>
            <h3 className={classes.OrderGroupName}>Anhang</h3>

            <List>
                {props.attachments.map((attachment, index) => (
                    <ListItem key={attachment.attachmentId || index } button onClick={() => props.onDownload(attachment)}>
                        <ListItemIcon>
                            <FolderIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={attachment.filename || attachment.name}
                            secondary={filesize(attachment.filesize || attachment.size)}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

AttachmentList.propTypes = {
    attachments: PropTypes.array,
    onDownload: PropTypes.func.isRequired
};

export default AttachmentList;

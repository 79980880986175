import { API } from 'aws-amplify';
import axios from 'axios';

import { extractAttachmentsFromForm, extractDataFromForm } from '../../utils/form-utils';
import * as actions from './actions';

export const getOrderList = () => async dispatch => {
    try {
        dispatch(actions.ordersListStart());

        const orderList = await API.get('default', '/orders', null);
        for (const order of orderList) {
            order.createdAt = order.createdAt && new Date(order.createdAt);
        }

        dispatch(actions.ordersListSuccess(orderList));
    } catch (err) {
        dispatch(actions.ordersListFail(err));
    }
};

export const createOrder = (formType, form) => async dispatch => {
    try {
        const formData = extractDataFromForm(form);
        const attachments = extractAttachmentsFromForm(form);

        dispatch(actions.newOrderStart(formData));

        const newOrder = await API.post('default', '/orders', {
            body: {
                formType,
                formData
            }
        });

        for (const attachment of attachments) {
            console.log(`Uploading Attachment: ${attachment.name}`);

            const presignedS3 = await API.post('default', `/orders/${newOrder.userId}/${newOrder.orderId}/attachments`, {
                body: {
                    filename: attachment.name,
                    contentType: attachment.type,
                    filesize: attachment.size
                }
            });

            await axios.put(presignedS3.url, attachment, {
                headers: {
                    'Content-Type': attachment.type,
                    'Content-Disposition': `filename="${attachment.name}"`
                }
            });
        }

        dispatch(actions.newOrderSuccess());
    } catch (err) {
        dispatch(actions.newOrderFail(err));
    }
};

export const getOrder = (userId, orderId) => async dispatch => {
    try {
        dispatch(actions.orderGetStart());

        const order = await API.get('default', `/orders/${userId}/${orderId}`, null);
        order.createdAt = order.createdAt && new Date(order.createdAt);

        dispatch(actions.orderGetSuccess(order));
    } catch (err) {
        dispatch(actions.orderGetFail(err));
    }
};

export const getOrderAttachment = (userId, orderId, attachmentId) => async dispatch => {
    try {
        dispatch(actions.orderGetAttachmentStart());

        const res = await API.get('default', `/orders/${userId}/${orderId}/attachments/${attachmentId}`, null);
        window.open(res.url, '_blank');

        dispatch(actions.orderGetAttachmentSuccess());
    } catch (err) {
        dispatch(actions.orderGetAttachmentFail(err));
    }
};

export const closeOrderForm = () => dispatch => {
    dispatch(actions.closeOrderForm());
};

import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import OrderPositionTable from '../../../components/OrderPositionTable/OrderPositionTable';
import AddAccessoryDialog from '../AddAccessoryDialog/AddAccessoryDialog';

const AccessoriesForm = props => {

    const [ addDialogOpened, setAddDialogOpened ] = useState(false);

    const tableHeader = ['Pos.', 'Art', 'Fertigfundament (nur bei DC)', 'Erdstück (nur bei AC)', 'Stele/Standfuß + KÜK (nur bei AC)', 'Anfahrschutz (bei AC und DC)'];

    const addDialogSubmitHandler = formData => {
        const locationType = formData.type ? formData.type.toUpperCase() : '-';
        const orderPosition = {
            values: [locationType, ...formData.accessories],
            content: [
                locationType,
                formData.accessories.includes('Fertigfundament'),
                formData.accessories.includes('Erdstück'),
                formData.accessories.includes('Stele/Standfuß + KÜK*'),
                formData.accessories.includes('Anfahrschutz**'),
            ]
        };

        props.onAddOrderPosition(orderPosition);
        setAddDialogOpened(false);
    };

    return (
        <>
            <OrderPositionTable
                label={props.label}
                header={tableHeader}
                onAddPosition={() => setAddDialogOpened(true)}
                onRemovePosition={props.onRemoveOrderPosition}
                positions={props.positions}
            />

            <AddAccessoryDialog open={addDialogOpened} onClose={() => setAddDialogOpened(false)} onSubmit={addDialogSubmitHandler} />
        </>
    );
};

AccessoriesForm.propTypes = {
    label: PropTypes.string.isRequired,
    positions: PropTypes.array.isRequired,
    onAddOrderPosition: PropTypes.func.isRequired,
    onRemoveOrderPosition: PropTypes.func.isRequired
};

export default AccessoriesForm;

import React, { useState } from 'react';
import OrderPositionTable from '../../../components/OrderPositionTable/OrderPositionTable';
import * as PropTypes from 'prop-types';
import AddHardwareDialog from '../AddHardwareDialog/AddHardwareDialog';

const HardwareForm = props => {
    const [ addDialogOpened, setAddDialogOpened ] = useState(false);

    const tableHeader = ['Pos.', 'Ladestationstyp', 'Begrenzung Ladeleistung', 'Backendanbindung'];

    const addDialogSubmitHandler = formData => {
        const orderPosition = {
            values: [ formData.type, formData.chargeTrottling, formData.connectionMethod ],
            content: [
                formData.type,
                formData.chargeTrottling,
                formData.connectionMethod
            ]
        };

        props.onAddOrderPosition(orderPosition);
        setAddDialogOpened(false);
    };

    return (
        <>
           <OrderPositionTable
               label={props.label}
               header={tableHeader}
               onAddPosition={() => setAddDialogOpened(true)}
               positions={props.positions}
               onRemovePosition={props.onRemoveOrderPosition}
           />
            <AddHardwareDialog open={addDialogOpened} onClose={() => setAddDialogOpened(false)} onSubmit={addDialogSubmitHandler} />
        </>
    );
};

HardwareForm.propTypes = {
    label: PropTypes.string.isRequired,
    positions: PropTypes.array.isRequired,
    onAddOrderPosition: PropTypes.func.isRequired,
    onRemoveOrderPosition: PropTypes.func.isRequired,
};

export default HardwareForm;

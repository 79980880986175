import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './containers/Home/Home';
import Signin from './containers/Signin/Signin';
import OrderForm from './containers/OrderForm/OrderForm';
import OrderDetail from './containers/OrderDetail/OrderDetail';
import PrivateRoute from './containers/PrivateRoute/PrivateRoute';

const publicRoutes = [
    { path: '/signin', exact: true, component: Signin }
];

const authenticatedRoutes = [
    { path: '/', exact: true, component: Home },
    { path: '/orders/new/:formType', component: OrderForm },
    { path: '/orders/:userId/:orderId', component: OrderDetail }
];

const Routes = () => (
    <Switch>
        {authenticatedRoutes.map(route => (
            <PrivateRoute key={route.path} {...route} />
        ))}

        {publicRoutes.map(route => (
            <Route key={route.path} {...route} />
        ))}

        <Route path="*" render={() => <span>Seite nicht gefunden.</span>}/>
    </Switch>
);

export default Routes;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const HtmlHelpText = ({ text }) => (
  <Typography
      dangerouslySetInnerHTML={{ __html: text }}
      variants="p"
      style={{fontSize: '0.75rem', lineHeight: '1rem'}}
  />
);

HtmlHelpText.propTypes = {
  text: PropTypes.string.isRequired
};

export default HtmlHelpText;

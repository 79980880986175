import React from 'react';
import { IconButton, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, Checkbox } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import * as PropTypes from 'prop-types';

import * as classes from './OrderPositionTable.module.css';

const OrderPositionTable = props => {

    const buttonGroup = (
        <div className={classes.ButtonGroup}>
            <Typography variant="subtitle1" gutterBottom>{props.label}</Typography>
            <div>
                <Button variant="contained" color="primary" size="small" onClick={props.onAddPosition}>
                    <AddIcon />
                </Button> 
            </div>
        </div>
    );

    const table = (
        <Table className={classes.PositionTable}>
            <TableHead>
                <TableRow>
                    {props.header.map(h => <TableCell key={h}>{h}</TableCell>)}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { props.positions && props.positions.map((p, index) => (
                    <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        {p.content.map((content, contentIndex) => (
                            <TableCell key={index + '-' + contentIndex}>
                                {typeof content === 'boolean' ? <Checkbox readOnly={true} checked={content} /> : content}
                            </TableCell>
                        ))}
                        <TableCell>
                            <IconButton size="small" onClick={() => props.onRemovePosition(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )) }
            </TableBody>
        </Table>
    );

    return (
        <>
            {buttonGroup}
            {table}
        </>
    );
};

OrderPositionTable.propTypes = {
    label: PropTypes.string.isRequired,
    header: PropTypes.array.isRequired,
    positions: PropTypes.array,
    onAddPosition: PropTypes.func.isRequired,
    onRemovePosition: PropTypes.func.isRequired
};

export default OrderPositionTable;

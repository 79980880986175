import React from 'react';
import Layout from './hoc/Layout/Layout';
import Routes from './Routes';

const App = () => (
    <Layout>
        <Routes/>
    </Layout>
);

export default App;

import * as actionTypes from './actionTypes';

export const authStart = () => ({
    type: actionTypes.AUTH_START
});

export const authSuccess = (user, account) => ({
    type: actionTypes.AUTH_SUCCESS,
    user,
    account
});

export const authFail = error => ({
    type: actionTypes.AUTH_FAIL,
    error
});

export const authLogoutStart = () => ({
    type: actionTypes.AUTH_LOGOUT_START
});

export const authLogoutSuccess = () => ({
    type: actionTypes.AUTH_LOGOUT_SUCCESS
});

export const authLogoutFail = error => ({
    type: actionTypes.AUTH_LOGOUT_FAIL,
    error
});

export const authChallengeNewPasswordStart = user => ({
    type: actionTypes.AUTH_CHALLENGE_NEW_PASSWORD_START,
    user
});

export const authChallengeNewPasswordFail = error => ({
    type: actionTypes.AUTH_CHALLENGE_NEW_PASSWORD_FAIL,
    error
});

export const authPasswordResetStart = () => ({
    type: actionTypes.AUTH_PASSWORD_RESET_START
});

export const authPasswordResetSuccess = res => ({
    type: actionTypes.AUTH_PASSWORD_RESET_SUCCESS,
    res
});

export const authPasswordResetFail = error => ({
    type: actionTypes.AUTH_CHALLENGE_NEW_PASSWORD_FAIL,
    error
});

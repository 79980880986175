export const extractDataFromForm = form => {
    const formData = {
        groups: []
    };

    for (const group of form) {
        const formGroup = ({
            name: group.name,
            label: group.label
        });

        if (group.elements) {
            formGroup.values = [];

            for (const elementKey in group.elements) {
                if (!group.elements[elementKey].value) {
                    continue;
                }

                formGroup.values.push({
                    name: group.elements[elementKey].name,
                    label: group.elements[elementKey].label,
                    value: group.elements[elementKey].value
                });
            }
        } else if (group.positions) {
            formGroup.positions = group.positions.map(p => p.values);
        }

        if (formGroup.positions || formGroup.values.length > 0) {
            formData.groups.push(formGroup);
        }
    }

    return formData;
};

export const extractAttachmentsFromForm = form => {
    const attachments = [];

    for (const group of form) {
        if (!group.elements) {
            continue;
        }

        for (const elementKey in group.elements) {
            if (!group.elements[elementKey].file) {
                continue;
            }

            attachments.push(group.elements[elementKey].file);
        }
    }

    return attachments;
};
